import HeadText from "../HeadText"

function Contact({ title, address, links }) {
    return (
        <>
            <div className="py-12" id="contact">
                <HeadText title={title} />
            </div>
            <div className="py-12" style={{ background: `linear-gradient(90deg, rgba(255, 237, 186, 0.15) 50%, rgba(62, 171, 54, 0.07) 50%)` }}>
                <div className="max-w-screen-lg px-4 mx-auto">
                    <div className="flex flex-wrap items-center">
                        <div className="w-full md:w-1/2">
                            <img loading="lazy" src="images/logo.png" alt="" className="w-48 md:w-64 cursor-pointer mx-auto" />
                            <p className="plain_text text-center mt-2 md:mt-4" dangerouslySetInnerHTML={{__html: address}} />
                        </div>
                        <div className="md:px-14 w-full md:w-1/2 mt-8 md:mt-0">
                            <h1 className="head_text text-3xl md:text-4xl md:text-left text-center">Contact Info</h1>
                            <div className="mt-7 space-y-5 flex flex-col items-center md:items-start">
                                {links?.map((item, index) => (
                                    <a key={index} href={item?.link} className="flex items-center">
                                        <img loading="lazy" src={item?.icon} alt="" className="w-6 md:w-auto" />
                                        <p className="plain_text ml-4">{item?.title}</p>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact