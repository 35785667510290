function Product({ image, title }) {
    return (
        <div className="px-2 mb-10 w-full sm:w-1/2 md:w-1/3 cursor-pointer">
            <img loading="lazy" src={image} alt="" className="mx-auto" />
            <h3 className="text-center mt-5 text-lg font-medium plain_text">{title}</h3>
        </div>
    )
}

export default Product
