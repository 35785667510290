import Content from '../components/Content'
import Header from '../components/Header'
import OurProducts from '../components/OurProducts'
import OurStory from '../components/OurStory'
import Contact from '../components/Contact'
import { useEffect, useState } from 'react'

function HomePage() {
    const [siteData, setSiteData] = useState(null)

    useEffect(() => {
        fetch('/siteData.json').then(data => data.json()).then(data => setSiteData(data))
    }, [])

    if (!siteData) return <></>;

    return (
        <div className="">
            <Header menus={siteData?.menus} />
            <div style={{
                backgroundImage: `url("/images/body-bg.png")`,
                backgroundSize: "contain",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat"
            }}>
                <OurStory
                    text={siteData?.ourStory?.text}
                />
                <OurProducts
                    products={siteData?.ourProducts?.products}
                    title={siteData?.ourProducts?.title}
                />
                <Content
                    id="mission"
                    title={siteData?.mission1?.title}
                    text={siteData?.mission1?.text}
                    colors={['rgba(255, 237, 186, 0.20)', 'rgba(255, 237, 186, 0)']}
                />
                <Content
                    title={siteData?.mission2?.title}
                    text={siteData?.mission2?.text}
                    colors={['rgba(62, 171, 54, 0)', 'rgba(62, 171, 54, 0.10)']}
                />
                <Contact 
                    title={siteData?.contact?.title}
                    address={siteData?.contact?.address}
                    links={siteData?.contact?.links}
                />
            </div>
        </div>
    )
}

export default HomePage
