import { useEffect } from "react"
import "./style.css"

function Header({ menus }) {

    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach(item => {
            item.addEventListener("click", function (event) {
                event.preventDefault();
                document.querySelector(this.getAttribute("href")).scrollIntoView({ behavior: "smooth" });
            })
        })
    }, [])

    return (
        <div className="flex justify-center" style={{
            backgroundImage: `url("/images/header-bg.png")`,
            backgroundSize: "cover",
            backgroundPosition: "bottom"
        }}>
            <div className="py-5">
                <img loading="lazy" src="images/logo.png" alt="" className="w-60 md:w-80 cursor-pointer mx-auto" />
                <div className="flex justify-center mt-2 items-center">
                    {menus?.map((item, index) => (
                        <a key={index} href={item?.path} className="header_link font-medium px-4 py-1">{item?.title}</a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Header
