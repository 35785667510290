import HeadText from '../HeadText'
import Product from '../Product'

function OurProducts({ products, title }) {
    return (
        <div className="py-12" id="products">
            <div className="max-w-screen-lg px-4 mx-auto">
                <HeadText title={title} right />
                <div className="flex flex-wrap justify-center mt-16 md:px-20">
                    {products?.map((item, index) => (
                        <Product
                            key={index}
                            image={item?.image}
                            title={item?.title}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OurProducts
