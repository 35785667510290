function OurStory({ text }) {
    return (
        <div className="py-12" id="story">
            <div className="max-w-screen-lg px-4 mx-auto">
                <img src="/images/vegan_cheese.png" alt="" className="mx-auto h-14 md:h-24"/>
                <img src="/images/vegan_cheese_title.png" alt="" className="mt-4 mx-auto w-72 md:w-auto max-w-full"/>
                <p className="plain_text mt-8 text-center text-lg">{text}</p>
            </div>
        </div>
    )
}

export default OurStory
