function Content({ title, text, colors, id }) {
    return (
        <div className="py-12" style={{ background: `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)` }} id={id}>
            <div className="max-w-screen-lg px-4 mx-auto">
                <h1 className="text-center text-3xl sm:text-5xl md:text-6xl head_text">{title}</h1>
                <p className="plain_text mt-8 text-center text-lg">{text}</p>
            </div>
        </div>
    )
}

export default Content
