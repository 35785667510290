import styles from './HeadText.module.css'

function HeadText({ title, right = false }) {

    const textArr = title?.split(' ')
    right ? textArr.pop() : textArr.shift()

    return (
        <h1 className={styles.head_text + " text-center text-3xl sm:text-5xl md:text-6xl head_text"}>
            {right && textArr.join(' ')}{" "}
            {right && (
                <span className={styles.head_text_bg + " text-white"}>{title?.split(' ').pop()}</span>
            )}
            {!right && (
                <span className={styles.head_text_bg + " text-white"}>{title?.split(' ').shift()}</span>
            )}{" "}
            {!right && textArr.join(' ')}
        </h1>
    )
}

export default HeadText
